@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

:root {
  --background: #ffffff; /* Default light mode color */
  --primary: #1b1718;
  --darkBackground: #1b1718;
  --red: #ec3230;
  --blue: #38BDF8;
  --green: #5fc38d;
  /* color: #1b1718; */
  color: #5fc38d;
  font-family: 'DM+Sans', sans-serif; 
  font-weight: 700;
}

.dark-mode {
  background: #1b1718;
  color: #fff;
}

.appStore {
  height: 11.5vh;
  margin-bottom: 10vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
