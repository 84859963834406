.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--background);
  padding: 12px 40px;
}

.menu-open {
  padding: 42px 40px;
}

.navbar-logo {
  text-decoration: none;
  color: var(--primary);
  font-weight: bold;
  font-size: 20px;
}

.navbar-links {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.navbar-links.active {
  transform: translateX(0%);
}

.nav-link {
  text-decoration: none;
  color: var(--green);
  margin-left: 20px;
  font-weight: 700;
}

.navbar-hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 5px;
  margin-left: 10px;
}

.navbar-hamburger span {
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  background-color: var(--green);
}

.side-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 100vh;
  background: var(--green);
  padding: 40px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 999;
}

.side-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-menu li {
  margin-bottom: 30px;
}

.side-menu-link {
  text-decoration: none;
  color: var(--background);
  font-weight: bold;
  font-size: 25px;
  padding: 10px 0;
}

.close-button {
  font-size: large;
  display: block;
  margin-bottom: 10px;
  padding: 5px 10px;
  color: var(--background);
  border: 2px solid var(--background);
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 12px 5px;
  }

  .navbar-links {
    display: none;
  }

  .navbar-hamburger {
    display: flex;
  }

  .navbar-hamburger.active ~ .side-menu {
    transform: translateX(0%);
  }

  .navbar-hamburger.active span {
    background-color: #fff;
  }

  .side-menu {
    transform: translateX(0);
  }
}


