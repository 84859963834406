.home-page {
    display: flex;
    height: 100vh;
    background-color: var(--background);
  }
  
  .left-div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fade-in 1s ease-in-out forwards;
  }
  
  .right-div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fade-in 1s ease-in-out forwards;
  }
  
  .inner-element {
    margin: 20px;
    padding: 20px;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 576px) {
    .home-page {
      flex-direction: column;
    }
  
    .left-div,
    .right-div {
      width: 100%;
    }
  }
  