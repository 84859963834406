.contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .contact-heading {
    font-size: 2rem;
  }
  
  .contact-email {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  